.homeintro {

    padding-top: 220px;
    padding-bottom: 220px;
    padding-left: $default-padding;
    padding-right: $default-padding;

    @include breakpoint($sm) {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    h3 {
        font-size: 6.3vw;
        padding-right: 10%;
        font-weight: normal;
        line-height: line-height(120, 118);
    }

}

.homepic {

    padding-left: grid-value(10);
    padding-right: grid-value(11.6);

    @include breakpoint($lg) {
        padding-right: grid-value(10.5);
    }

    @include breakpoint($md) {
        padding-right: grid-value(9);
    }

    @include breakpoint($sm) {
        padding-left: 25px;
        padding-right: 25px;
    }

    figure, img {
        width: 100%;
        display: block;
        position: relative;
    }

    figure {

        padding-bottom: 13px;

        &:before {
            top: 33%;
            left: 24%;
            bottom: 0;
            z-index: 1;
            right: -13px;
            content: "\A";
            position: absolute;
            background-color: $c04;
        }

    }

    img {
        z-index: 2;
    }

}

.homeabout {

    padding-top: 50px;
    padding-bottom: 255px;

    @include breakpoint($sm) {
        padding-bottom: 70px;
    }

    &__container {

        padding-left: grid-value(10);
        padding-right: grid-value(2);

        @include breakpoint($sm) {
            padding-left: grid-value(7);
        }

    }

    &__container-big {

        padding-left: grid-value(8);
        padding-right: grid-value(2);

        @include breakpoint($sm) {
            padding-left: $default-padding;
            padding-right: $default-padding;
        }

    }

    &__inner {
        width: 100%;
        max-width: 340px;
    }

    h2, h3, h4 {
        font-weight: normal;
    }

    h2 {
        font-size: 24px;
        padding-bottom: 13px;
    }

    h4 {

        font-size: 24px;
        padding-top: 112px;
        padding-bottom: 17px;

        @include breakpoint($sm) {
            padding-top: 50px;
        }

    }

    p {
        font-size: 16px;
        line-height: line-height(16, 20);
    }

    ul {
        line-height: line-height(16, 19);
    }

}