@mixin background($background-image, $background-position: 'top left', $background-repeat: 'no-repeat') {
    @if $img-link-type == "REL" {
        background-image: url('#{$routeREL}#{$background-image}');
    } @else if $img-link-type == "ABS" {
        background-image: url('#{$routeABS}#{$background-image}');
    }
    background-repeat: #{$background-repeat};
    background-position: #{$background-position};
}

@mixin background-image($background-image) {
    @if $img-link-type == "REL" {
        background-image: url('#{$routeREL}#{$background-image}');
    } @else if $img-link-type == "ABS" {
        background-image: url('#{$routeABS}#{$background-image}');
    }
}

@mixin background-cover {
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}