html, body {
    width: 100%;
    height: 100%;
    display: block;
}

html {
    font-weight: normal;
    background-color: $c02;
    font-family: $font-main;
    background-repeat: repeat;
    background-position: top left;
    font-size: $browser-context+px;
    color: $default-body-text-color;
    @include background-image('bg-main.jpg');
    line-height: line-height($browser-context, $browser-line-height);
}

a {

    text-decoration: none;
    color: $default-body-link-color;

    &:hover {
        text-decoration: underline;
    }

}