//#######################################
//## ROUTE TYPE #########################
//#######################################

$img-link-type: REL;
// OR
//$img-link-type: ABS;

//#######################################
//## IMG ROUTES #########################
//#######################################

$routeREL: '../ui/';
$routeABS: '/static/ui/';

//#######################################
//## FONT ROUTES ########################
//#######################################

$routeRELfont: '../fonts/';
$routeABSfont: '/static/fonts/';