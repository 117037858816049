@mixin rotate($degrees: 0) {
    -webkit-transform: rotate(#{$degrees}deg);
    -moz-transform: rotate(#{$degrees}deg);
    -ms-transform: rotate(#{$degrees}deg);
    -o-transform: rotate(#{$degrees}deg);
    transform: rotate(#{$degrees}deg);
}

@mixin transform-scale($sizeX: 1, $sizeY: $sizeX) {
    -webkit-transform: scale($sizeX, $sizeY);
    -moz-transform: scale($sizeX, $sizeY);
    -ms-transform: scale($sizeX, $sizeY);
    -o-transform: scale($sizeX, $sizeY);
    transform: scale($sizeX, $sizeY);
}

@mixin transform-origin($origin) {
    -webkit-transform-origin: #{$origin};
    -moz-transform-origin: #{$origin};
    -ms-transform-origin: #{$origin};
    -o-transform-origin: #{$origin};
    transform-origin: #{$origin};
}

@mixin translate($x: 0, $y: $x) {
    -webkit-transform: translateX($x) translateY($y);
    -moz-transform: translateX($x) translateY($y);
    -ms-transform: translateX($x) translateY($y);
    -o-transform: translateX($x) translateY($y);
    transform: translateX($x) translateY($y);
}

@mixin translateScale($x: 0, $y: $x, $sizeX: 1, $sizeY: $sizeX) {
    -webkit-transform: translateX($x) translateY($y) scale($sizeX, $sizeY);
    -moz-transform: translateX($x) translateY($y) scale($sizeX, $sizeY);
    -ms-transform: translateX($x) translateY($y) scale($sizeX, $sizeY);
    -o-transform: translateX($x) translateY($y) scale($sizeX, $sizeY);
    transform: translateX($x) translateY($y) scale($sizeX, $sizeY);
}

@mixin translateXYZ($x: 0, $y: $x, $z: 0) {
    -webkit-transform: translateX($x) translateY($y) translateZ($z);
    -moz-transform: translateX($x) translateY($y) translateZ($z);
    -ms-transform: translateX($x) translateY($y) translateZ($z);
    -o-transform: translateX($x) translateY($y) translateZ($z);
    transform: translateX($x) translateY($y) translateZ($z);
}

@mixin translateY($translateY, $important: false) {
    @if($important) {
        -webkit-transform: translateY($translateY) !important;
        -moz-transform: translateY($translateY) !important;
        -ms-transform: translateY($translateY) !important;
        -o-transform: translateY($translateY) !important;
        transform: translateY($translateY) !important;
    }
    @else {
        -webkit-transform: translateY($translateY);
        -moz-transform: translateY($translateY);
        -ms-transform: translateY($translateY);
        -o-transform: translateY($translateY);
        transform: translateY($translateY);
    }
}

@mixin translateX($translateX) {
    -webkit-transform: translateX($translateX);
    -moz-transform: translateX($translateX);
    -ms-transform: translateX($translateX);
    -o-transform: translateX($translateX);
    transform: translateX($translateX);
}

@mixin translateZ($translateZ) {
    -webkit-transform: translateZ($translateZ);
    -moz-transform: translateZ($translateZ);
    -ms-transform: translateZ($translateZ);
    -o-transform: translateZ($translateZ);
    transform: translateZ($translateZ);
}