.header {

    h1, h2, h3 {
        font-weight: normal;
    }

    h1, h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 16px;
    }

    &__home {
        border-bottom: 1px solid $c03;
        padding: 25px $default-padding;
    }

    &__bottom {
        padding: 30px $default-padding;
    }

    &__home-left, &__bottom-left {
        float: left;
    }

    &__home-right {

        float: right;

        ul {
            padding-top: 4px;
        }

    }

    &__bottom-right {
        float: right;
    }

    a {
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: underline;
    }

    // ABOUT ME PAGE

    &__about {

        font-size: 17px;
        padding-top: 21px;

        h1 {
            font-size: 17px;
        }

        &-left {

            float: left;
            width: grid-value(6);
            padding-left: $default-padding;

            @include breakpoint($sm) {
                float: none;
                width: 100%;
            }

        }

        &-center {

            float: left;
            width: grid-value(14);

            @include breakpoint($sm) {
                float: none;
                width: 100%;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: $default-padding;
                padding-right: $default-padding;
            }

        }

        &-right {

            float: right;
            font-size: 14px;
            text-align: right;
            width: grid-value(4);
            padding-right: $default-padding;

            @include breakpoint($sm) {
                top: 0;
                right: 0;
                float: none;
                width: 100%;
                padding-top: 25px;
                position: absolute;
                padding-left: $default-padding;
                padding-right: $default-padding;
            }

        }

    }

}