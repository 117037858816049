@mixin breakpoint($mq1: 0, $mq2: false, $maxmin: max-width) {

    @if $mq2 == false {

        @media ($maxmin: $mq1) {
            @content;
        }

    }

    @else {

        @media (min-width: $mq1) and (max-width: $mq2) {
            @content;
        }

    }

}

@mixin orientation($orientation: landscape) {

    @media all and (orientation: $orientation) {
        @content;
    }

}
