.abouttitle {

    font-size: 6.3vw;
    padding-top: 82px;
    font-weight: normal;
    padding-bottom: 82px;
    padding-left: grid-value(6);
    padding-right: grid-value(4);
    line-height: line-height(120, 118);

    @include breakpoint($sm) {
        padding-top: 50px;
        padding-left: $default-padding;
        padding-right: $default-padding;
    }

}

.aboutme {

    &__left {

        float: left;
        margin-top: -6px;
        padding-right: 5%;
        width: grid-value(12);
        padding-left: grid-value(6);

        @include breakpoint($sm) {
            width: 100%;
            float: none;
            padding-right: 5%;
            padding-left: $default-padding;
        }

        p {
            font-size: 19px;
            padding-bottom: 22px;
            line-height: line-height(19, 23);
        }

    }

    &__right {

        float: left;
        width: grid-value(12);
        padding-right: grid-value(5);

        @include breakpoint($lg) {
            width: grid-value(11);
            padding-left: grid-value(0.5);
            padding-right: grid-value(1.5);
        }

        @include breakpoint($sm) {
            float: none;
            width: 100%;
            padding-left: 0;
            padding-top: 10px;
            padding-right: grid-value(5);
        }

        img {
            width: 100%;
            display: block;
        }

    }

}

.aboutawards {

    padding-top: 82px;
    padding-bottom: 256px;

    @include breakpoint($lg) {
        padding-bottom: 200px;
    }

    @include breakpoint($sm) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__left {

        float: left;
        padding-top: 10px;
        width: grid-value(12);
        padding-right: grid-value(2);
        padding-left: grid-value(5.5);

        @include breakpoint($lg) {
            width: grid-value(12.5);
        }

        @include breakpoint($sm) {
            float: none;
            width: 100%;
            padding-bottom: 35px;
            padding-left: grid-value(4);
            padding-right: grid-value(2);
        }

    }

    &__center {

        float: left;
        width: grid-value(3);

        @include breakpoint($lg) {
            width: grid-value(4);
        }

        @include breakpoint($sm) {
            float: none;
            width: 100%;
            padding-left: grid-value(4);
            padding-right: grid-value(2);
        }

    }

    &__right {

        float: left;
        width: grid-value(3);

        @include breakpoint($lg) {
            width: grid-value(4);
        }

        @include breakpoint($sm) {
            float: none;
            width: 100%;
            padding-left: grid-value(4);
            padding-right: grid-value(2);
        }

    }

    h2 {
        font-size: 24px;
        font-weight: normal;
        padding-bottom: 13px;
    }

    p {
        font-size: 16px;
        line-height: line-height(16, 19);
    }

    ul {

        font-size: 19px;
        line-height: line-height(19, 21);

        li {
            padding-bottom: 17px;
        }

    }

}