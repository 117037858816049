//########################################
//## FONT VARIABLES ######################
//########################################

$font-main: 'FoundersGrotesk-Regular';

@include font-face($font-main);

//########################################
//## SIZES ###############################
//########################################

$browser-context: 16; // px
$browser-line-height: 28; // px