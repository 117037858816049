.footer {

    font-size: 14px;
    padding: 20px 0;
    border-top: 1px solid $c03;
    line-height: line-height(14, 20);

    &__nav {

        float: left;
        width: grid-value(10);
        text-transform: uppercase;
        padding-left: $default-padding;

        @include breakpoint($sm) {
            float: none;
            width: 100%;
        }

        li {
            margin-right: 47px;
            display: inline-block;
        }

        a {
            text-decoration: underline;
        }

    }

    &__email {

        float: left;
        display: block;
        font-size: 24px;
        width: grid-value(8);

        @include breakpoint($sm) {
            float: none;
            width: 100%;
            padding: 15px $default-padding;
        }

        a {
            text-decoration: underline;
        }

    }

    &__social {

        float: right;
        padding-left: 10px;
        width: grid-value(1);
        padding-right: $default-padding;

        @include breakpoint($lg) {
            width: grid-value(2);
        }

        @include breakpoint($md) {
            width: grid-value(3);
        }

        @include breakpoint($sm) {
            float: none;
            width: 100%;
            padding: 0 $default-padding;
        }

    }

}